import React from "react";
import Layout from "../components/reusable/Layout";
import { graphql } from "gatsby";
import Seo from "../components/reusable/Seo";
import { useTranslation } from "react-i18next";
import { Linkedin } from "styled-icons/bootstrap";
import {
  CompaniesSection,
  FaqsContainer,
  Hero,
  InfoServicesSection,
  MainInfoSection,
  TalentSpecialistSection,
  ContactSection,
} from "../styles/pages/executive-search";
import Carousel from "../components/reusable/Carousel";
import star from "../images/executive-search/star.svg";
import userStar from "../images/executive-search/user-stars.svg";
import clock from "../images/executive-search/clock.svg";
import { TeamSection } from "../styles/pages/about-us";
import Faqs from "../components/reusable/Faqs";
import { Bg, Text } from "../components/ui";
import { Box, Button, Grid } from "@mui/material";

const oxxo =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2F135.png?alt=media&token=4a4a1547-d32a-4231-bc60-8e45385304b1";
const banregio =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2F136.png?alt=media&token=b0f7e419-98ee-4c81-9c1c-53ac684e893a";
const femsa =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2F137.png?alt=media&token=1478f68d-9941-4d41-af72-b6abc0940d58";
const abin =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2F138.png?alt=media&token=d300ed2e-1cdf-4502-8cf2-1ed6582e15b8";
const abi5 =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2F139.png?alt=media&token=0db0ecf7-9616-4725-bb44-bb996b55fbbf";
const abi6 =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2F140.png?alt=media&token=6f48e09b-cffe-46a9-a156-75b56588a264";
const abi7 =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2F141.png?alt=media&token=44062897-81d0-44c3-b15b-88e00402d38a";

const talentSpecialits =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fexecutive-search%2FSpecialist.png?alt=media&token=cc1f2bf4-9a4b-4631-9281-b3458a4c46c1";
const talentPlatform =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2Ftalent-platform.png?alt=media&token=c0aef133-37ec-4aa4-bd5f-621397f6145f";
const eSearch =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fexecutive-search%2FHeader.png?alt=media&token=2703b619-5d97-45cb-90e1-6d1330d0e161";
const eATS =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fexecutive-search%2FallCompanies.png?alt=media&token=5a265587-66c4-4782-9a25-b0f9675faeb1";
const ExecutiveSearch = () => {
  const { t } = useTranslation("executive-search");

  const team = [
    {
      name: "Coni Riveros",
      position: "People & Culture Director en Dentalia",
      oldPosition: "Ex Managing Partner en NUMA",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fexecutive-search%2Fmembers%2FCONI%20RIVEROS.png?alt=media&token=e115d79c-eb70-4367-8de6-7da1d2c95771",
      ulrLinkendIn: "https://www.linkedin.com/in/coniriveros/",
    },
    {
      name: "David Hoffs",
      position: "Chief Finance Officer en Fondeadora Inc",
      oldPosition: "Ex Chief Strategy Officer en Quiero Casa",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fexecutive-search%2Fmembers%2FDAVID%20HOFFS.png?alt=media&token=9ee305d5-9515-413b-ace0-dce1d147024a",
      ulrLinkendIn: "https://www.linkedin.com/in/david-hoffs-8b281531/",
    },
    {
      name: "Daphne S. Leger",
      position: "CEO & Founder en Atrevidea",
      oldPosition: "Ex Innovation Manager en Cinepolis",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fexecutive-search%2Fmembers%2FDAPHNE%20LEGER.png?alt=media&token=7302a6fd-9756-416f-b23f-45217de78f05",
      ulrLinkendIn: "https://www.linkedin.com/in/daphnesleger/",
    },
    {
      name: "Álvaro Ruiz",
      position: "Head of Sales de Buk",
      oldPosition: "Ex Country Manager en Talent.com",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fexecutive-search%2Fmembers%2FA%CC%81LVARO%20RUI%CC%81Z.png?alt=media&token=266a76fa-90d5-4ac1-89c4-3a8074e7d81e",
      ulrLinkendIn: "https://www.linkedin.com/in/alvaroruizpu/",
    },
    {
      name: "Ana Zamacona",
      position: "Head of Brand & Communications en Fondeadora",
      oldPosition: "Ex Associate Creative Director en TERAN/TBWA",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fexecutive-search%2Fmembers%2FANA%20ZAMACONA.png?alt=media&token=d07be60d-04aa-4cf6-841e-e37800e76a93",
      ulrLinkendIn: "https://www.linkedin.com/in/anazam/",
    },
    {
      name: "Fabiola Bórquez",
      position: "Senior Product Manager en Scotiabank",
      oldPosition: "Ex Head of Product en Runa",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fexecutive-search%2Fmembers%2F1.png?alt=media&token=86c0747b-0b8c-4344-b62d-ac61f6485cc6",
      ulrLinkendIn: "https://www.linkedin.com/in/fabiborquez/",
    },
    {
      name: "Jorge Fernández",
      position: "Fintech specialist",
      oldPosition: "Ex General Manager Débito en Fondeadora",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fexecutive-search%2Fmembers%2FJORGE%20FERNANDEZ.png?alt=media&token=a8b9d8ee-d529-4058-bf40-86470d5dd306",
      ulrLinkendIn: "https://www.linkedin.com/in/jorge-fern%C3%A1ndez-braun/",
    },
    {
      name: "Jorge Arturo Fernandez",
      position: "Business & Health Director en Mellow Consulting",
      oldPosition: "Ex Medical Director en Carecheck",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fexecutive-search%2Fmembers%2FJORGE%20ARTURO%20FERNANDEZ.png?alt=media&token=d919a09f-1251-4970-95a1-4591d0e855f0",
      ulrLinkendIn: "https://www.linkedin.com/in/jorgefernandezmd/",
    },
    {
      name: "Jedidiah Horne ",
      position: "Head of Engineering- Klym",
      oldPosition: "Ex Uber",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fexecutive-search%2Fmembers%2FJEDIDIAH.png?alt=media&token=cd19d08e-7ce6-4307-8c4e-b6b9bdcfe475",
      ulrLinkendIn: "https://www.linkedin.com/in/jedhorne/",
    },
  ];

  const faqs = [
    {
      question: t("question1"),
      answer: t("answer1"),
    },
    {
      question: t("question2"),
      answer: t("answer2"),
    },
    {
      question: t("question3"),
      answer: t("answer3"),
    },
  ];
  return (
    <Layout modeHeader="transparent">
      <Seo
        canonical="https://empresas.lapieza.io/executive-search"
        title="Executive search - Para posiciones ejecutivas y de dirección"
        image="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fpreviews%2FLANDING%20EMPRESAS.png?alt=media&token=3dd56d07-4544-4eef-ab5c-5b53a475137e"
      />
      <Bg.Linear>
        <Hero>
          <div className="hero-content">
            <Box sx={{ maxWidth: "454px", marginTop: "20px" }}>
              <Text.Title
                text={t("heroTitle")}
                sx={{
                  display: "block",
                  textAlign: "start",
                  fontSize: "42px",
                  marginBottom: "20px",
                }}
              />
              <Text.Body
                text={t("heroDesc")}
                sx={{
                  display: "block",
                  textAlign: "start",
                  fontSize: "18px",
                  marginBottom: "50px",
                }}
              />
              <Button
                href="https://api.whatsapp.com/send/?phone=527771369552&text=%C2%A1Hola%20Alexa!%20Me%20interesa%20registrarme%20y%20saber%20m%C3%A1s%20sobre%20los%20servicios%20de%20LaPieza"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  width: "230px",
                  fontSize: "16px",
                  mb: 5,
                }}
              >
                <Text.Body
                  text={t("callAcction")}
                  sx={{
                    color: "primary.white",
                    fontSize: "16px",
                    textAlign: "start",
                  }}
                />
              </Button>
            </Box>
            <Box>
              <img src={eSearch} alt="Image description" />
            </Box>
          </div>
        </Hero>
      </Bg.Linear>
      <CompaniesSection>
        <Text.Title
          text={t("companySectionTitle")}
          sx={{
            display: "block",
            textAlign: "center",
            fontSize: "38px",
            marginBottom: "20px",
          }}
        />
        <div className="companies-section">
          <Carousel
            marque
            logos={[oxxo, banregio, femsa, abin, abi5, abi6, abi7]}
            width="28rem"
          />
        </div>
      </CompaniesSection>
      <TalentSpecialistSection>
        <div className="main-landing-container-row">
          <figure>
            <img src={talentSpecialits} alt="Talent Specialist" />
          </figure>

          <article>
            <Text.Title
              text={t("talentTitle")}
              sx={{
                display: "block",
                textAlign: "start",
                fontSize: "38px",
                marginBottom: "20px",
              }}
            />
            <Text.Body
              text={t("talentDescription")}
              sx={{
                display: "block",
                textAlign: "start",
                fontSize: "18px",
                marginBottom: "20px",
              }}
            />

            <Button
              href="https://api.whatsapp.com/send/?phone=527771369552&text=%C2%A1Hola%20Alexa!%20Me%20interesa%20registrarme%20y%20saber%20m%C3%A1s%20sobre%20los%20servicios%20de%20LaPieza"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                width: "280px",
                fontSize: "16px",
                mb: 5,
              }}
            >
              <Text.Body
                text={t("requestInfo")}
                sx={{
                  color: "primary.white",
                  fontSize: "16px",
                  textAlign: "start",
                }}
              />
            </Button>
          </article>
        </div>
      </TalentSpecialistSection>
      <Bg.Gradient>
        <InfoServicesSection>
          <span className="main-title-services">
            <Text.Title
              text={t("infoTitle")}
              sx={{
                display: "block",
                textAlign: "start",
                fontSize: "38px",
                marginBottom: "20px",
              }}
            />
          </span>
          <div className="sevices-list">
            <article className="service-item">
              <figure>
                <img src={star} alt="stars" />
              </figure>
              <Box sx={{ maxWidth: "270px" }}>
                <Text.Title
                  text={t("infoItemOneTitle")}
                  sx={{
                    display: "block",
                    textAlign: "start",
                    fontSize: "32px",
                    marginBottom: "20px",
                  }}
                />
                <Text.Body
                  text={t("infoItemOneDescription")}
                  sx={{
                    display: "block",
                    textAlign: "start",
                    fontSize: "18px",
                    marginBottom: "20px",
                  }}
                />
              </Box>
            </article>
            <article className="service-item">
              <figure>
                <img src={userStar} alt="stars" />
              </figure>
              <Box sx={{ maxWidth: "290px" }}>
                <Text.Title
                  text={t("infoItemTwoTitle")}
                  sx={{
                    display: "block",
                    textAlign: "start",
                    fontSize: "32px",
                    marginBottom: "20px",
                  }}
                />
                <Text.Body
                  text={t("infoItemTwoDescription")}
                  sx={{
                    display: "block",
                    textAlign: "start",
                    fontSize: "18px",
                    marginBottom: "20px",
                  }}
                />
              </Box>
            </article>
            <article className="service-item">
              <figure>
                <img src={clock} alt="stars" />
              </figure>
              <Box sx={{ maxWidth: "300px" }}>
                <Text.Title
                  text={t("infoItemThreeTitle")}
                  sx={{
                    display: "block",
                    textAlign: "start",
                    fontSize: "32px",
                    marginBottom: "20px",
                  }}
                />
                <Text.Body
                  text={t("infoItemThreeDescription")}
                  sx={{
                    display: "block",
                    textAlign: "start",
                    fontSize: "18px",
                    marginBottom: "20px",
                  }}
                />
              </Box>
            </article>
          </div>
        </InfoServicesSection>
      </Bg.Gradient>
      <MainInfoSection>
        <div className="main-landing-container-row main-info-platform">
          <article>
            <Box sx={{ maxWidth: "440px" }}>
              <Text.Title
                text={t("mainFisrtTitle")}
                sx={{
                  display: "block",
                  textAlign: "start",
                  fontSize: "38px",
                  marginBottom: "20px",
                }}
              />
              <Text.Body
                text={t("mainFisrtDescription")}
                sx={{
                  display: "block",
                  textAlign: "start",
                  fontSize: "18px",
                  marginBottom: "20px",
                }}
              />
            </Box>
          </article>
          <figure>
            <img src={talentPlatform} alt="Platform ATS" />
          </figure>
        </div>
        <div className="main-landing-container-row main-info-bussiness">
          <figure>
            <img src={eATS} alt="Platform ATS" />
          </figure>
          <Box sx={{ maxWidth: "480px" }}>
            <article>
              <Text.Title
                text={t("mainSecondTitle")}
                sx={{
                  display: "block",
                  textAlign: "start",
                  fontSize: "38px",
                  marginBottom: "20px",
                }}
              />
              <Text.Body
                text={t("mainSecondDescription")}
                sx={{
                  display: "block",
                  textAlign: "start",
                  fontSize: "18px",
                  marginBottom: "20px",
                }}
              />
              <Button
                href="https://api.whatsapp.com/send/?phone=527771369552&text=%C2%A1Hola%20Alexa!%20Me%20interesa%20registrarme%20y%20saber%20m%C3%A1s%20sobre%20los%20servicios%20de%20LaPieza"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  width: "280px",
                  fontSize: "16px",
                  mb: 5,
                }}
              >
                <Text.Body
                  text={t("requestInfo")}
                  sx={{
                    color: "primary.white",
                    fontSize: "16px",
                    textAlign: "start",
                  }}
                />
              </Button>
            </article>
          </Box>
        </div>
      </MainInfoSection>
      {/* <CostumerSection>
        <div className="main-landing-container-column">
          <h2>
            {t("testimonialTitle")}
            <img src={figures} alt="Ilustración de LaPieza" />
          </h2>
          <CarouselTestimonials />
        </div>
      </CostumerSection> */}
      <TeamSection>
        <div className="main-landing-container-column">
          <Text.SubTitle
            text={t("Algunos de nuestros expertos")}
            sx={{
              display: "block",
              textAlign: "center",
              fontSize: "16px",
              marginBottom: "10px",
            }}
          />
          <Text.Title
            text={t("Consultores de Executive Search")}
            sx={{
              display: "block",
              textAlign: "center",
              fontSize: "38px",
              marginBottom: "40px",
            }}
          />
          <div className="team-list">
            {team.map((item, idx) => (
              <>
                {item.picture && (
                  <article key={`member-item-${idx}`}>
                    <figure>
                      <img src={item.picture} alt="Foto de Pol en LaPieza" />
                    </figure>
                    <div>
                      <a
                        href={item.ulrLinkendIn}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h5>
                          <Linkedin
                            className="icon-linkedin"
                            size={20}
                            color="#0a66c2"
                          />
                          <Text.Title
                            text={item.name}
                            sx={{ textAlign: "center", fontSize: "18px" }}
                          />
                        </h5>
                      </a>
                      <Text.Body
                        text={item.position}
                        sx={{
                          fontSize: "16px",
                          textAlign: "center",
                          display: "block",
                        }}
                      />
                      <Text.Body
                        text={item.oldPosition}
                        sx={{
                          fontSize: "16px",
                          textAlign: "center",
                          display: "block",
                        }}
                      />
                    </div>
                  </article>
                )}
              </>
            ))}
          </div>
        </div>
      </TeamSection>
      <FaqsContainer>
        <div className="main-landing-container-column">
          <Text.Title
            text={t("faqsTitle")}
            sx={{
              fontSize: "24px",
              textAlign: "start",
              display: "block",
              marginBottom: "40px",
            }}
          />
          <Faqs faqs={faqs} className="faqs" />
        </div>
      </FaqsContainer>
      <ContactSection>
        <div className="main-landing-container-column">
          <Box sx={{ maxWidth: "650px" }}>
            <Text.Title
              text={t("contactSectionTitle")}
              sx={{
                fontSize: "38px",
                textAlign: "center",
                display: "block",
                marginBottom: "40px",
              }}
            />
            <Text.Body
              text={t("contactSectionDescription")}
              sx={{
                fontSize: "18px",
                textAlign: "center",
                display: "block",
                marginBottom: "40px",
              }}
            />
          </Box>
          <Button
            href="https://api.whatsapp.com/send/?phone=527771369552&text=%C2%A1Hola%20Alexa!%20Me%20interesa%20registrarme%20y%20saber%20m%C3%A1s%20sobre%20los%20servicios%20de%20LaPieza"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              width: "280px",
              fontSize: "16px",
              mb: 5,
            }}
          >
            <Text.Body
              text={t("requestInfo")}
              sx={{
                color: "primary.white",
                fontSize: "16px",
                textAlign: "start",
              }}
            />
          </Button>
        </div>
      </ContactSection>
    </Layout>
  );
};

export default ExecutiveSearch;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
