import styled from "styled-components";
import { fonts, landingPalette } from "../styles";

export const Hero = styled.header`
  min-height: 47rem;
  background-color: #fffbf1;
  padding: 8rem 80px 8rem 80px;

  @media (max-width: 600px) {
    padding: 8rem 32px 8rem 32px;
  }

  @media (max-width: 425px) {
    padding: 8rem 16px 8rem 16px;
  }

  .main-landing-container-row {
    display: flex;
    justify-content: space-between;
    grid-gap: 4rem;

    @media (max-width: 920px) {
      flex-direction: column;
    }
  }

  h1 {
    font-size: 23px;
    line-height: 29px;
    color: ${landingPalette.orange};
    font-family: ${fonts.SofiaProMedium};
    margin-bottom: 16px;
  }

  h2 {
    font-size: 3.8rem;
    line-height: 4.8rem;
    color: ${landingPalette.blue};
    font-family: ${fonts.SofiaProBold};
    max-width: 56rem;
    margin-bottom: 5.4rem;
  }

  p {
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: ${landingPalette.blue};
    font-family: ${fonts.SofiaProMedium};
    max-width: 80rem;
  }

  article {
    position: relative;
    z-index: 1;
  }

  figure {
    align-self: center;

    img {
      max-width: 28.8rem;
      object-fit: scale-down;
    }
  }
`;

export const AboutUsSection = styled.section`
  padding: 8rem 80px 8rem 80px;

  @media (max-width: 600px) {
    padding: 8rem 32px 8rem 32px;
  }

  @media (max-width: 425px) {
    padding: 8rem 16px 8rem 16px;
  }

  .about-section {
    align-items: unset;
    > article h2 {
      font-size: 3.8rem;
      line-height: 2.9rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProSemiBold};
      margin-bottom: 3rem;
    }

    > article p {
      font-size: 2.4rem;
      line-height: 3.2rem;
      color: ${landingPalette.greyChevron};
      font-family: ${fonts.sofiaProRegular};
      max-width: 90.6rem;
      margin-bottom: 8rem;
    }
  }

  .about-us-list {
    display: flex;
    grid-gap: 3.2rem;
    justify-content: space-between;

    @media (max-width: 1199px) {
      flex-wrap: wrap;
    }

    ul {
      max-width: 66.2rem;
      width: 100%;
      display: grid;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: flex;
        grid-gap: 3rem;
      }

      article {
        padding-bottom: 4.6rem;
      }

      .dot-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          display: block;
        }
        .dot {
          height: 18px;
          width: 18px;
          border-radius: 45px;
          background-color: ${landingPalette.orange};
        }

        .dot-line {
          height: 100%;
          width: 2px;
          background-color: ${landingPalette.bgBlue};
        }
      }

      h5 {
        font-size: 2rem;
        margin-bottom: 1.6rem;
        color: ${landingPalette.blue};
        font-family: ${fonts.SofiaProSemiBold};

        span {
          font-size: 1.8rem;
          font-family: ${fonts.SofiaProSemiBold};
          background-color: #f7f5f1;
          border-radius: 14px;
          padding: 4px 9px;
          margin-right: 8px;
        }
      }

      p {
        font-family: ${fonts.sofiaProRegular};
        font-size: 1.8rem;
        line-height: 2.8rem;
        color: ${landingPalette.blue};
      }
    }

    figure {
      max-width: 40rem;
      width: 100%;
      align-self: flex-start;
      justify-self: flex-start;

      @media (max-width: 1199px) {
        margin: auto;
      }
      img {
        width: 100%;
        object-fit: scale-down;
      }
    }
  }
`;

export const HiringSection = styled.section`
  padding: 8rem 80px 0 80px;

  @media (max-width: 600px) {
    padding: 8rem 32px 0 32px;
  }

  @media (max-width: 425px) {
    padding: 8rem 16px 0 16px;
  }

  .hiring-section {
    border-radius: 10px;
    background-color: ${landingPalette.bgBlue};
    display: flex;
    justify-content: space-between;
    grid-gap: 8rem;
    padding: 4rem 10rem;

    @media (max-width: 920px) {
      flex-direction: column;
    }

    @media (max-width: 768px) {
      padding: 4rem 5rem;
    }

    h2 {
      font-size: 3.8rem;
      line-height: 2.9rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProBold};
      margin-bottom: 1.6rem;
    }

    p {
      font-size: 2.4rem;
      line-height: 3.2rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProMedium};
      max-width: 100rem;
      margin-bottom: 3.2rem;
    }

    button,
    a {
      border: none;
      outline: none;
      background-color: ${landingPalette.orange};
      border-radius: 21px;
      min-height: 44px;
      padding: 10px 30px;
      font-family: ${fonts.SofiaProMedium};
      font-size: 1.6rem;
      color: white;
      line-height: 2rem;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
    }

    figure {
      max-width: 18rem;
      width: 100%;

      img {
        width: 100%;
        object-fit: scale-down;
      }
    }
  }
`;

export const TeamSection = styled.section`
  padding: 5rem 80px 10rem 80px;

  @media (max-width: 600px) {
    padding: 5rem 32px 10rem 32px;
  }

  @media (max-width: 425px) {
    padding: 5rem 16px 10rem 16px;
  }

  a {
    text-decoration: none;
  }

  h2 {
    font-size: 23px;
    line-height: 29px;
    color: ${landingPalette.orange};
    font-family: ${fonts.SofiaProMedium};
    margin-bottom: 16px;
    text-align: center;
  }

  h3 {
    font-size: 3.8rem;
    line-height: 4.8rem;
    color: ${landingPalette.blue};
    font-family: ${fonts.SofiaProBold};
    text-align: center;
    margin-bottom: 9rem;
  }

  .team-list {
    display: flex;
    flex-wrap: wrap;
    gap: 6rem; /* Ajuste del espacio entre elementos */
    margin: auto;
    justify-content: center; /* Centra todos los elementos */
      align-items: center;

    article {
      max-width: calc(
        (100% - 2 * 6rem) / 3
      ); /* Ajusta para tres elementos por fila */
      flex: 1 1 calc((100% - 2 * 6rem) / 3); /* Ajusta el tamaño de los elementos */
      width: 100%;
      display: flex;
      flex-direction: column; /* Asegura una disposición vertical */
      align-items: center; /* Centra el contenido horizontalmente */
      figure {
        margin-bottom: 2rem;
        border-radius: 10px;
        height: 232px;
        width: 232px;
        display: flex;
        align-items: center; /* Centra verticalmente */
        justify-content: center; /* Centra horizontalmente */
        overflow: hidden; /* Asegura que la imagen no se salga del contenedor */
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }

      h5 {
        font-size: 16px;
        line-height: 20px;
        color: ${landingPalette.blueText};
        font-family: ${fonts.SofiaProSemiBold};
        text-align: center;
        margin-bottom: 1rem;

        .icon-linkedin {
          margin-right: 10px;
        }
      }

      p {
        font-size: 16px;
        line-height: 19px;
        color: ${landingPalette.greyChevron};
        font-family: ${fonts.sofiaProRegular};
        text-align: center;
      }
    }
  }
    @media (max-width: 768px) {
        article {
            max-width: 100% !important;
            align-items: center;
            width: 100%;
        }
        
        .team-list {
            flex-direction: column; /* Cambia a una columna */
            gap: 3rem; /* Reduce el espacio entre elementos */
        }

        figure {
            height: 180px; /* Reduce el tamaño de las imágenes para pantallas pequeñas */
            width: 180px;
        }

        h5 {
            font-size: 14px; /* Ajusta el tamaño del texto */
        }

        p {
            font-size: 14px;
        }
`;

export const InversorsSection = styled.section`
  padding: 8rem 80px 0 80px;

  @media (max-width: 600px) {
    padding: 8rem 32px 0 32px;
  }

  @media (max-width: 425px) {
    padding: 8rem 16px 0 16px;
  }

  .inverstor-section {
    border-radius: 10px;
    background-color: ${landingPalette.bgBlue};
    display: flex;
    justify-content: space-between;
    grid-gap: 8rem;
    padding: 6rem 10rem;

    @media (max-width: 920px) {
      flex-direction: column;
    }

    @media (max-width: 768px) {
      padding: 6rem 5rem;
      justify-content: center;
      text-align: center;
    }

    h2 {
      font-size: 3.8rem;
      line-height: 2.9rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProBold};
      margin-bottom: 3rem;
    }

    p {
      font-size: 2.4rem;
      line-height: 3.2rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProMedium};
      max-width: 100rem;
      margin-bottom: 3.2rem;
    }

    button,
    a {
      border: none;
      outline: none;
      background-color: ${landingPalette.orange};
      border-radius: 21px;
      min-height: 44px;
      padding: 10px 30px;
      font-family: ${fonts.SofiaProMedium};
      font-size: 1.6rem;
      color: white;
      line-height: 2rem;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
    }

    figure {
      max-width: 18rem;
      width: 100%;

      img {
        width: 100%;
        object-fit: scale-down;
      }
    }

    > div {
      display: flex;
      grid-gap: 5rem;

      @media (max-width: 600px) {
        flex-direction: column;
      }
    }
  }
`;
